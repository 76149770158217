/* นำเข้า Noto Sans Thai จาก Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Noto Sans Thai', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* กำหนดคลาส custom-swal-popup ให้ชัดเจน */
.custom-swal-popup {
  font-family: 'Noto Sans Thai', sans-serif !important;
}

/* เพิ่มการกำหนด style ให้กับ SweetAlert2 */
.custom-swal-title {
  font-family: 'Noto Sans Thai', sans-serif !important;
  font-weight: 600 !important;
}

.custom-swal-html {
  font-family: 'Noto Sans Thai', sans-serif !important;
}

.custom-swal-button {
  font-family: 'Noto Sans Thai', sans-serif !important;
  font-weight: 500 !important;
  border-radius: 0.375rem !important;
  padding: 0.5rem 1.5rem !important;
  transition: background-color 0.15s ease-in-out !important;
}

.custom-swal-footer {
  font-family: 'Noto Sans Thai', sans-serif !important;
}

/* เพิ่ม Class อื่นๆ ที่เกี่ยวข้องกับ SweetAlert2 */
.swal2-popup {
  font-family: 'Noto Sans Thai', sans-serif !important;
}
.swal2-title {
  font-family: 'Noto Sans Thai', sans-serif !important;
}
.swal2-html-container {
  font-family: 'Noto Sans Thai', sans-serif !important;
}
.swal2-confirm-button, .swal2-cancel-button {
  font-family: 'Noto Sans Thai', sans-serif !important;
}

/* กำหนดสี hover สำหรับปุ่ม */
.swal2-confirm.custom-swal-button:hover {
  background-color: #2563eb !important;
}

.swal2-cancel.custom-swal-button:hover {
  background-color: #dc2626 !important;
}

/* ซ่อน password reveal icon จากเบราว์เซอร์ */

/* Microsoft Edge */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* Chrome และ Safari */
input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

